/* stylelint-disable no-duplicate-selectors, property-no-unknown, selector-type-no-unknown, declaration-block-no-duplicate-properties  */
/*** DONATION FORM ***/

/* default donation submit button color */
.node-type-page .default-form-wrapper #donation-form-column-wrapper input.form-submit,
.node-type-donation-form .node input#edit-submit,
.node-type-donation-form .node #edit-submit.form-submit {
  background-color: $submit-btn-bg;
  border: 2px solid $submit-btn-bg;
  &:hover {
    background-color: $submit-btn-hover-bg;
    border: 2px solid $submit-btn-hover-bg;
  }
}

/* default donation submit button placement and size */
.node-type-page .default-form-wrapper #donation-form-column-wrapper,
.node-type-ticketed-event,
.node-type-p2p-donation-form,
.page-fundraiser-offline,
.node-type-donation-form {
    #edit-actions.form-actions {
        padding-left: 28%;
        @include susy-breakpoint($breakpoint-small, $small) {
          padding-left: 0;
       }
    }
    input.form-submit,
    input#edit-submit,
    input#edit-submit.btn,
    #edit-submit.form-submit {
      width: auto;
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      float: none;
    }
    input.form-submit.paypal-default-submit,
    #edit-paypal-submit {
      height: auto;
      border: none;
      display: none;
    }
   /* hide default sb4 payment features we are not using */
   #webform-component-payment-information {
     #webform-component-payment-information--recurs-monthly,
     #webform-component-sbp-salutation,
     #webform-component-sbp-phone,
     #webform-component-payment-information--payment-method {
       display: none;
     }
   }
   /* hide threatmetrix related additions */
   .fluid-width-video-wrapper {
     display: none;
   }
   /* hide threatmetrix related additions */
   #edit-submitted-payment-information-payment-fields-credit img {
     display: none;
   }
}

.node-type-p2p-donation-form,
.node-type-ticketed-event,
.page-fundraiser-offline,
.node-type-donation-form {
   &.alt-color {
     fieldset legend {
       background-color: #990000;
       color: #fff;
     }
     input[type="checkbox"] + label:before {
      background: icon-url("checkboxes-red-bg.png") no-repeat;
      background-position: 0 -23px;
      @include susy-breakpoint($breakpoint-small, $small) {
          width: 52px;
          height: 48px;
          background: icon-url("checkboxes-large-red-bg.png") no-repeat;
          float: left;
          background-position: 0 -47px;
       }
     }
   }
   %donation-input-style {
      width: 100px;
      height: 25px;
      line-height: 25px;
      margin-bottom: 0px;
      font-size: 19px;
      padding: 5px;
   }
   %donation-label-style {
    float: left;
    width: auto;
    min-width: 202px;
    margin-right: 10px;
    font-weight: normal;
    font-size: 22px;
    line-height: 1.5em;
    padding: 5px 0 5px 0;
    vertical-align: middle;
    color: #414141;
    display: block;
   }
   %donation-right-float {
      float: right;
      display: block;
      margin: 20px;
      max-width: 100% !important;
      @include susy-breakpoint($breakpoint-small, $small) {
        float: left;
        margin-right: 100%;
        margin-left: 0;
      }
      @include susy-breakpoint($breakpoint-medium, $medium) {
        float: left;
        margin-left: 0;
        margin-right: 100%;
      }
      @include resolution-breakpoint  {
        float: left;
        margin-left: 0;
        margin-right: 100%;
      }
   }
   div.donation-processing-wrapper {
    float: none;
    margin: 0;
    .processing-spinner  {
      margin-left: 40%;
    }
   }
   .webform-component-radios > div {
     margin: 0;
   }
   .node-donation-form .content {
       margin-top: 0;
      .mobile-only-content, .field-name-field-aclu-summary {
        display: none;
      }
      @include susy-breakpoint($breakpoint-small, $small) {
        .field-name-body,
        .field-name-field-aclu-right-column,
        .right-text,
        .full-content {
          display: none;
        }
        .mobile-only-content, .field-name-field-aclu-summary {
          display: block;
        }
      }
    }
    .form-item select {
      max-width: 15rem;
    }
   .button-label {
       &.recurring-square {
         @include susy-breakpoint($breakpoint-large-up, $large) {
            display: none;
         }
         @include resolution-breakpoint  {
            display: inline-block;
         }
         @include susy-breakpoint($breakpoint-small-medium, $medium) {
            display: inline-block;
         }
       }
       &.recurring-simple {
         @include susy-breakpoint($breakpoint-large-up, $large) {
           display: inline-block;
         }
         @include resolution-breakpoint  {
           display: none;
         }
         @include susy-breakpoint($breakpoint-small-medium, $medium) {
           display: none;
         }
      }
    }
    div.error.other-amount-wrapper {
      .currency-in-input {
        input#edit-submitted-donation-recurring-other-amount,
        input#edit-submitted-donation-other-amount {
          margin-top: 0;
          float: left;
          clear: both;
          border: 2px solid #cc0000;
          width: 80%;
          max-width: 250px;
        }
      }
     }
   .form-item .currency-in-input {
        display: inline-block;
       .symbol {
         float: left;
         line-height: 33px;
         padding-left: 5px;
         @include susy-breakpoint($breakpoint-large-up, $large) {
           display: none;
         }
         @include resolution-breakpoint  {
           display: inline-block;
         }
         @include susy-breakpoint($breakpoint-small-medium, $medium) {
           display: inline-block;
         }
       }
       label.error {
         display: block;
         float: left;
         clear: both;
         margin-left: 0;
         width: 100%;
         max-width: 180px;
         min-width: 110px;
       }
       @include resolution-breakpoint  {
         display: inline-block;
         border: 1px inset #444;
         margin: 10px 0 15px 0;
       }
       @include susy-breakpoint($breakpoint-small-medium, $medium) {
         display: inline-block;
         border: 1px inset #444;
         margin: 10px 0 15px 0;
       }
     }
    .currency-in-input {
      input#edit-submitted-donation-recurring-other-amount,
      input#edit-submitted-donation-other-amount {
        @include susy-breakpoint($breakpoint-large-up, $large) {
          border: solid 1px #999;
        }
        @include resolution-breakpoint  {
           border: 0;
        }
        @include susy-breakpoint($breakpoint-small-medium, $medium) {
           border: 0;
        }
        @include susy-breakpoint($breakpoint-small, $small) {
          width: 80%;
          max-width: 250px;
        }
      }
    }
   #webform-component-payment-information--payment-fields {
     div.error.form-item.security-code-wrapper.form-item-submitted-payment-information-payment-fields-credit-card-cvv {
       display: inline;
     }
   }

   #webform-component-donation,
   #webform-component-payment-information--payment-method {
      input[type=radio] {
        display: inline-block;
        @include susy-breakpoint($breakpoint-large-up, $large) {
          margin-top: 8px;
          margin-right: 10px;
          float: left;
        }
        @include resolution-breakpoint  {
          float: none;
        }
        @include susy-breakpoint($breakpoint-small-medium, $medium) {
          float: none;
        }
      }
      margin-top: 5px;
      #webform-component-donation--amount .form-item,
      #webform-component-donation--recurring-amount .form-item  {
        display: inline-block;
        margin-top: 0px;
        overflow: hidden;
        label.option {
          min-width: 0;
          vertical-align: top;
          float: right;
          @include susy-breakpoint($breakpoint-large-up, $large) {
            padding-top: 10px;
            line-height: 17px;
            vertical-algin: middle;
          }
          @include resolution-breakpoint  {
            padding-top: 10px;
          }
          @include susy-breakpoint($breakpoint-small-medium, $medium) {
            padding-top: 10px;
          }
          .form-required {
            display: none;
          }
        }
      }
    }
    #webform-component-payment-information--payment-method {
      .form-type-radio {
        display: inline-block;
      }
      @include susy-breakpoint($breakpoint-small-medium, $medium) {
        .psuedo-radio-label,
        input[type=radio] {
            @include square-radiobutton(#fff, #ffcc33, #333, 0px 10px, 10px 20px 10px 0, auto);
         }
         .psuedo-radio-label + label ,
         input[type=radio] + label {
            color: #373e44;
            font-size: 18px;
            float: left;
            padding: 0 10px;
         }
      }
      .form-item label.option {
        min-width: 100px;
      }
    }
    #webform-component-donation .monthly-wrapper.form-item {
      margin-left: 40px;
      @include resolution-breakpoint  {
        margin-left: 0;
      }
      @include susy-breakpoint($breakpoint-small-medium, $medium) {
        margin-left: 0;
      }
    }
    #webform-component-required-disclaimer,
    #webform-component-optional-c3-disclaimer,
    #webform-component-optional-c4-disclaimer {
      margin: 0;
      line-height: 19px;
    }
    #webform-component-optional-c3-disclaimer,
    #webform-component-optional-c4-disclaimer,
    .form-required-info {
      font-family: Georgia,"Times New Roman","DejaVu Serif",serif;
    }
    p.donation_disclaimer {
      font-family: DIN,Arial,Helvetica,sans-serif !important;
      line-height: 19px !important;
      padding: 1rem 0 0 0 !important;
    }
    p.donation_disclaimer:empty {
      padding: 0px !important;
    }
    #edit-submitted-billing-information-country {
      min-width: 302px;
    }
    #recurring-message {
       display: block;
       color: #3366cc;
       font-family: "lavoshandyregular", Arial, Helvetica, "Nimbus Sans L", sans-serif;
       margin-left: 0;
       width: 100%; //for secondary advo forms
       max-width: auto;
       p {
         color: #3399cc;
         font-family: "lavoshandyregular", Arial, Helvetica, "Nimbus Sans L", sans-serif;
       }
       div.message-wrapper {
         width: auto;
         display: table;
       }
       div.icon {
         display: table-cell;
       }
       div.arrow,
       span.arrow {
          background-image: icon-url("recurring-arrow.png");
          background-repeat: no-repeat;
          height: 24px;
          width: 27px;
       }
       &.show-msg {
         margin-top: -15px;
         display: inline-block;
         .custom-message {
           display: none;
         }
         .message {
           display: table-cell;
         }
         &.buttons-stacked {
           max-width: 235px;
         }
         &.buttons-not-stacked {
           max-width: auto !important;
         }
         @include resolution-breakpoint  {
           margin-top: 0px;
         }
         @include susy-breakpoint($breakpoint-small, $small) {
           margin-top: 0px;
         }
         @include susy-breakpoint($breakpoint-medium, $medium) {
           margin-top: 0px;
         }
       }
       @include resolution-breakpoint  {
         margin-left: 65px;
         max-width: auto;
       }
       @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: 15px;
         max-width: 235px;
       }
       @include susy-breakpoint($breakpoint-medium, $medium) {
         margin-left: 65px;
         max-width: auto;
       }
       div.custom-message,
       div.message {
         display: none;
         vertical-align: bottom;
         padding-top: 7px;
         width: auto;
         @include susy-breakpoint($breakpoint-small, $small) {
          min-width: 200px;
         }
         @include susy-breakpoint($breakpoint-medium, $medium) {
           min-width: 350px;
         }
         @include resolution-breakpoint  {
           min-width: 350px;
         }
         @include susy-breakpoint($breakpoint-large-up, $large) {
           min-width: 350px;
         }
       }
    }
    #gift-suggestion-text {
      color: #3366cc;
      font-family: "lavoshandyregular", Arial, Helvetica, "Nimbus Sans L", sans-serif;
      margin-left: 0;
      width: auto;
    }
   .donation-buttons-wrapper {
     fieldset:not(:first-of-type):not(#webform-component-extra-fields):not(#webform-component-donation),
     #donation-form-footer,
     #edit-actions {
       @include resolution-breakpoint  {
         display: none;
       }
       @include susy-breakpoint($breakpoint-medium, $medium) {
         display: none;
       }
       @include susy-breakpoint($breakpoint-small, $small) {
          display: none;
       }
     }
     #donation-form-footer {
       @include susy-breakpoint($breakpoint-large-up, $large) {
         #extra-fields-wrapper,
         #webform-component-extra-fields {
           display: none;
         }
       }
      }
    .signup-wrapper {
       display: none;
       margin-left: 20px;
       margin-bottom: 10px;
       @include resolution-breakpoint  {
          display: block;
          margin-left: 15px;
          label {
             display: none;
             &.option {
               display: block;
               font-size: 21px;
             }
          }
       }
       @include susy-breakpoint($breakpoint-medium, $medium) {
          display: block;
          margin-left: 15px;
          label {
             display: none;
             &.option {
               display: block;
               font-size: 21px;
             }
          }
       }
        @include susy-breakpoint($breakpoint-small-medium, $medium) {
          &.form-item {
            clear: both;
            overflow: hidden;
          }
       }
       @include susy-breakpoint($breakpoint-small, $small) {
          display: block;
          margin-left: 15px;
          label {
             display: none;
             &.option {
               display: block;
               font-size: 21px;
             }
          }
       }
     }
   }
    #webform-component-donation.donation-buttons {
       margin-top: toRem(10px);
       .donation-label {
          display: none;
          @include susy-breakpoint($breakpoint-large-up, $large) {
            display: block;
          }
          @include resolution-breakpoint  {
            display: none;
          }
        }
       @include susy-breakpoint($breakpoint-large-up, $large) {
         margin-top: toRem(10px);
       }
       #extra-fields-wrapper,
       #webform-component-extra-fields {
         display: none;
         @include resolution-breakpoint  {
           display: block;
         }
         @include susy-breakpoint($breakpoint-small, $small) {
           display: block;
         }
         @include susy-breakpoint($breakpoint-medium, $medium) {
           display: block;
         }
         #email-signup-wrapper {
           .option {
             line-height: 21px;
             font-size: 19px;
             @include resolution-breakpoint  {
               line-height: 21px;
               font-size: 19px;
             }
             @include susy-breakpoint($breakpoint-small, $small) {
               line-height: 48px;
               font-size: 21px;
             }
             @include susy-breakpoint($breakpoint-medium, $medium) {
               line-height: 21px;
               font-size: 19px;
             }
           }
         }
       }
       @include susy-breakpoint($breakpoint-small-medium, $medium) {
         @include payment-options-large-buttons;
       }
       @include resolution-breakpoint  {
         @include payment-options-large-buttons;
       }
     #webform-component-donation--contribution-message,
     .contribution-message {
        font-size: 25px;
     }
     #webform-component-extra-fields--payment-options {
       display: none;
       @include susy-breakpoint($breakpoint-small, $small) {
         display: block;
       }
       .psuedo-radio-label .button-label,
       input[type=radio] .button-label {
          font-weight: 600;
          text-transform: uppercase;
       }

     }
     #webform-component-extra-fields--payment-message {
       font-family: DINWeb,Arial,Helvetica,sans-serif;
       display: none;
       border-radius: 5px;
       -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
       text-align: center;
       width: 240px;
       padding-left: 24px;
       margin-bottom: 20px;
       padding-right: 10px;
       margin-top: 20px;
       vertical-align: top;
     }
   }
    #webform-component-tribute-information--tribute-gift-type {
      input[type=radio] {
        float: left;
        margin: 7px 10px 0 0;
      }
    }
   .node-type-donation-form .form-item label.option.other-label-amount,
   .node-type-p2p-donation-form .form-item label.option.other-label-amount {
     margin-left: 0px;
   }
  .field-name-body .hero-item {
     width: auto !important;
     height: auto !important;
     max-width: 100% !important;
    img {
      width: auto !important;
      height: auto !important;
      max-width: none;
     }
   }
  .select {
    margin: 3px 5px 1px 0;
    max-width: 100%;
    label {
      display: inline-block;
    }
  }
  div {
     .description {
        @extend %donation-label-style;
      }
  }
  .form-item label {
    @extend %donation-label-style;
    &.option {
      float: left;
      &.other-label-amount {
        margin-right: 5px;
      }
     }
    &.custom-width {
      min-width: 0;
      max-width: 202px;
    }
    &.error {
      float: none;
      font-size: 16px;
      margin-left: 212px;
      @include susy-breakpoint($breakpoint-small, $small) {
        margin-left: 0px;
      }
    }
  }
  .currency-in-input .form-item label.error {
      margin-left: 0;
    }
  @include susy-breakpoint($breakpoint-small, $small) {
    .form-item.form-item-submitted-payment-information-payment-fields-credit-card-cvv label {
      min-width: 60px;
     &.error {
       margin-left: 60px;
     }
    }
  }
  h1,
  h1.page-title,
  p.page-title,
  div.page-title h1,
  div.page-title {
      font-size: toRem(30px);
      line-height: toRem(30px);
      text-transform: none;
      margin-bottom: toRem(10px);
      color: $page-title-color;
      font-weight: 700;
  }
  h2, div.page-subtitle {
    h2,
    .field-name-field-aclu-subtitle {
      font-weight: normal;
      color: $page-title-color;
      text-transform: none;
      font-size: toRem(24px);
      line-height: toRem(24px);
      margin-top: 0px;
      margin-bottom: toRem(16px);
      @include resolution-breakpoint  {
        margin-bottom: toRem(16px);
      }
      @include susy-breakpoint($breakpoint-small, $small) {
        margin-bottom: toRem(16px);
      }
      @include susy-breakpoint($breakpoint-medium, $medium) {
        margin-bottom: toRem(16px);
      }
    }
  }
  #header-webform-nav {
    .menu-item-act  {
       display: none;
     }
    .menu-item-donate  {
      display: none;
    }
  }
  fieldset {
    border: 0 none;
    padding: 0;
    width: 100%;
    > div {
      @include susy-breakpoint($breakpoint-wide, $wide) {
        max-width: toRem(750px);
        width: toRem(750px);
      }
    }
    legend {
      width: 100%;
      border: 0 none;
      font-size: 22px;
      line-height: 22px;
    }
    &#webform-component-send-an-ecard--ecard-fields {
      margin-top: 0;
      #webform-component-send-an-ecard--ecard-fields--recipient-email-address label.error {
        margin-left: 264px;
      }
      #webform-component-send-an-ecard--ecard-fields--ecard-message label.error {
        margin-left: 0;
        clear: both;
      }
    }
    &#webform-component-donation #webform-component-donation--other-amount,
    &#webform-component-donation #webform-component-donation--recurring-other-amount {
       .form-item {
         overflow: visible;
        }
       #webform-component-donation--other-amount .form-item,
       #webform-component-donation--recurring-other-amount .form-item {
        overflow: hidden;
        margin: 0;
       }
     }
    }
  .form-display #webform-component-biographical-information,
  #webform-component-biographical-information {
    border: none;
  }
  .donation-list {
    display: inline;
    list-style-type: none;
    padding-right: 2px;
    label {
      display: inline;
    }
  }
  .donation-label {
    margin-top: 3px;
  }
  #webform-component-donation--amount {
    .amount-text-field-wrapper {
       max-width: 175px;
       white-space: nowrap;
       clear: right;
       @include susy-breakpoint($breakpoint-small, $small) {
         clear: both;
       }
       @include susy-breakpoint($breakpoint-medium, $medium) {
         clear: right;
       }
       .field-prefix {
          display: inline-block;
       }
     }
     #edit-submitted-donation-amount {
        .form-item {
          margin-right: 0;
        }
        .form-text.text-amount {
          max-width: 150px;
          display: inline-block;
        }
     }
  }
  .other_amount_wrapper,
  #webform-component-donation--other-amount,
  #webform-component-donation--recurring-other-amount {
    display: block;
    clear: none;
    margin-top: 0px;
   .form-item label {
      display: none;
    }
    .option {
      @extend .donation-label;
    }
    &.webform-component {
       margin-right: 5px;
       overflow: visible;
       clear: none;
    }
    input[type="number"], input[type="text"] {
      @extend %donation-input-style;
    }
    .description {
      padding: 0;
      max-width: 90px;
      p {
       display: none;
       visibility: hidden;
      }
    }
  }
  .other_amount_wrapper,
  #webform-component-donation--other-amount,
  #webform-component-donation--recurring-other-amount {
    overflow: hidden;
    margin: 3px 0 0 0;
    padding: 0;
    float: none;
    display: inline-block;
    vertical-align: top;
    width: auto;
    height: 100%;
    text-align: left;
    label {
        display: none;
    }
    input[type="number"], input[type="text"] {
      @extend %donation-input-style;
      float: right;
    }
    .description {
      display: none;
      visibility: hidden;
    }
    .prefix-span-wrapper,
    .field-prefix {
      display: block;
      float: left;
      font-size: toRem(19px);
      line-height: toRem(35px);
      padding-left: toRem(3px);
      vertical-align: top;
     .prefix-text {
        @include susy-breakpoint($breakpoint-large-up, $large) {
          display: none;
        }
      }
    }
    .prefix-span-wrapper,
    span.prefix-span-wrapper {
      margin-left: 0;
      @include susy-breakpoint($breakpoint-large-up, $large) {
        margin-left: 0;
      }
    }
    span {
      &.field-prefix {
        display: block;
        margin-right: 2px;
        @include resolution-breakpoint  {
          display: none;
        }
        @include susy-breakpoint($breakpoint-small-medium, $medium) {
          display: none;
        }
        @include susy-breakpoint($breakpoint-large-up, $large) {
          display: block;
        }
      }
    }
  }
  .emailoptin-moved {
    margin-bottom: toRem(15px);
    margin-left: toRem(15px);
  }
  @include susy-breakpoint($breakpoint-small, $small) {
    .emailoptin-moved {
      display: block;
    }
  }
  #webform-component-credit-card-information,
  #webform-component-payment-information {
     .form-checkboxes,
     .webform-component-checkboxes {
          display: inline;
     }
    .cc-info-holder {
        width: 100%;
        #webform-component-credit-card-information--card-cvv,
        .form-item-submitted-payment-information-payment-fields-credit-card-cvv {
          float: none;
          height: 50px;
          width: 280px;
          .description p {
            margin: 0;
          }
          .form-text,
          input#edit-submitted-credit-card-information-card-cvv,
          input#edit-submitted-payment-information-payment-fields-credit-card-cvv {
            width: 50px;
            line-height: 25px;
            font-size: 19px;
          }
         }
         .expiration-date-wrapper {
            clear: both;
          }
          #edit-submitted-credit-card-information-expiration-date-card-expiration-year,
          #edit-submitted-credit-card-information-expiration-date-card-expiration-month,
          #edit-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-year,
          #edit-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month {
            width: auto;
            min-width: 0;
          }
     }
    #webform-component-payment-information--profile-may-we-share-your-info.form-item {
      display: block;
      margin-top: 0.75em;
    }
    #webform-component-credit-card-information--profile-may-we-share-your-info,
    #webform-component-payment-information--profile-may-we-share-your-info.form-item {
      .description {
        font-family: DINWeb,Arial,Helvetica,sans-serif;
        font-size: 14px;
        line-height: 17px;
        padding: 0 0 0 25px;
        width: auto;
        @include susy-breakpoint($breakpoint-small, $small) {
          padding: 0 0 0 50px;
        }
        p {
          font-family: DINWeb,Arial,Helvetica,sans-serif;
          font-size: 14px;
          line-height: 17px;
        }
       }
    }
  .your-info-field .form-checkbox,
  .option #edit-submitted-payment-information-profile-may-we-share-your-info-1 {
    font-family: DINWeb,Arial,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 17px;
     p {
      font-family: DINWeb,Arial,Helvetica,sans-serif;
      font-size: 14px;
      line-height: 17px;
     }
    }
  }

  .field-name-body,
  .body-wrapper {
     overflow: hidden;
     margin-top: 0px;
     margin-bottom: 10px;
     ul {
      list-style: disc outside none;
      font-family: Georgia,"Times New Roman","DejaVu Serif",serif !important;
      font-size: 100%;
      margin-left: toRem(16px);
      overflow: hidden;
     }
    li {
      margin-bottom: toRem(10px);
      font-family: Georgia,"Times New Roman","DejaVu Serif",serif !important;
      line-height: toRem(22px);
      font-size: 100%;
    }
    .hero-item, .right-image, .field-name-field-aclu-right-column {
       @extend %donation-right-float;
       width: auto !important;
       height: auto !important;
       text-align: center;
       max-width: none;
       min-width: 0;
       font-size: toRem(15px);
       -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
       box-sizing: border-box;
       overflow: hidden;
       @include susy-breakpoint($breakpoint-small, $small) {
         text-align: left;
       }
       img {
         width: auto !important;
         height: auto !important;
         max-width: 100%;
       }
       p {
          margin-top: 10px;
          line-height: toRem(19px);
          @include resolution-breakpoint  {
            font-size: 100%;
          }
          @include susy-breakpoint($breakpoint-small, $small) {
            font-size: 90%;
           }
           @include susy-breakpoint($breakpoint-medium, $medium) {
            font-size: 100%;
           }
       }
     }
     .field-name-field-aclu-right-column {
        margin: 0 0 0 20px;
        @include susy-breakpoint($breakpoint-medium, $medium) {
         margin: 0 20px 20px 0;
        }
        max-width: none;
        .field-item.even.div:empty {
           margin: 0;
        }
        .field-item.even.div {
          margin-right: 20px;
          margin-left: 20px;
          margin-bottom: 20px;
        }
        img {
          max-width: none;
        }
     }
     .field-name-body .field-items {
       overflow: hidden;
     }
     .right-text {
         @extend %donation-right-float;
         border: 1px solid #ccc;
         padding: 10px;
         color: #666;
         font-size: toRem(16px);
         line-height: toRem(20px);
         width: toRem(200px);
         max-width: toRem(200px);
         font-family: Arial, Helvetica, sans-serif;
     }
     .right-content {
       &.field-name-body {
         max-width: toRem(880px);
       }
     }
     #webform-component-form-body-header {
        margin-bottom: 45px;
        max-width: 750px;
        @include resolution-breakpoint  {
          display: none;
        }
        @include susy-breakpoint($breakpoint-small, $small) {
          display: none;
         }
         @include susy-breakpoint($breakpoint-medium, $medium) {
          display: none;
         }
     }
  }
  .fundraiser_submit_message {
    display: none !important;
  }
  #donation-form-footer {
    .description {
      display: none;
    }
    .warning {
      margin-bottom: 25px;
    }
    position: relative;
    clear: both;
  }
  .your-info-label a,
  label[for=edit-submitted-credit-card-information-profile-may-we-share-your-info-1] a {
    text-decoration: underline;
  }
}
.default-form-wrapper .fundraiser-confirmation ~ .aclu-donate-share-simple {
  padding-top: 0px;
}
@include susy-breakpoint($breakpoint-small, $small) {
  .default-form-wrapper .fundraiser-confirmation table[width] {
    width: 300px;
  }
  .default-form-wrapper .fundraiser-confirmation ~ .aclu-donate-share-simple {
    height: auto;
    margin-left: 0px;
    padding-top: 0px;
  }
}

/* begin paypal for desktop specified node */
@include susy-breakpoint($breakpoint-large-up, $large) {
   /* DEV node: 32263 */
  .node-type-donation-form #node-32263,
  .node-type-donation-form #node-58425,
  .node-type-donation-form #node-58153,
  .node-type-donation-form #node-58160,
  .node-type-donation-form #node-58161 {
    .donation-buttons-wrapper {
      fieldset:not(:first-of-type) {
        display: none;
      }
      #donation-form-footer {
        display: none;
      }
      .signup-wrapper {
        display: block;
        margin-left: 15px;
      }
      .form-required-info {
        display: none;
      }
    }
    #webform-component-donation {
      input[type=radio] {
        float: none;
      }
    }
    #webform-component-donation.donation-buttons {
      .donation-label {
        display: none;
      }
      legend {
        display: none;
      }
      #webform-component-donation--amount {
        .other_amount_wrapper,
        #webform-component-donation--other-amount {
          input[type="number"],
          input[type="text"] {
            float: left;
            width: 250px;
            clear: right;
            border: 0;
          }
          .prefix-span-wrapper {
            .prefix-text {
              padding-right: 20px;
              font-size: 22px;
              font-weight: 600;
              display: block;
            }
            .field-prefix {
              display: none;
            }
          margin-top: 10px;
          }
        }
        .psuedo-radio-label,
        input[type=radio] {
          &:checked {
            + label .button-label {
              font-weight: bold;
            }
          }
          &:hover + .button-label,
          &:checked + button-label,
          &:hover + label,
          &:checked + label {
            font-weight: bold;
            background-color: #ffcc33;
            background-image: none;
          }
          + label {
            color: #373e44;
            font-size: 22px;
          }
          + label.option {
            display: inline-block;
            margin: 10px 20px 10px 0;
            padding: 0;
            height: 75px;
            line-height: 75px;
            min-width: 0;
            border: 1px solid #333;
            cursor: pointer;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            text-align: center;
            width: 90px;
          }
          @include square-radiobutton(#fff, #ffcc33, #333, 24px 20px, 10px 20px 10px 0, 90px);
        }
        label {
          width: 100%;
        }
        outline-width: 0;
      }
      #extra-fields-wrapper {
        display: block;
      }
      .form-item .form-radios .form-item {
        display: inline-block;
      }
      #payment-options .psuedo-radio-label,
      #payment-options input[type=radio] {
        + label {
          font-size: 19px;
          text-transform: uppercase;
          font-weight: 600;
          color: #373e44;
        }
        + label.option {
          display: inline-block;
          margin: 5px 20px 5px 0;
          padding: 0;
          height: 75px;
          line-height: 75px;
          min-width: 0;
          background-color: #fff;
          border: 1px solid #333;
          cursor: pointer;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          text-align: center;
          width: 270px;
        }
        + label .button-label {
          display: block;
          text-align: center;
        }
        &:checked + label {
          font-weight: bold;
          background-color: #ffcc33;
        }
        &:hover + label {
          font-weight: bold;
          background-color: #ffcc33;
        }
        @include square-radiobutton(#fff, #ffcc33, #333, 24px 20px, 5px 20px 5px 0, 270px);
      }
      #recurring-message {
        div.message {
          display: table-cell;
          min-width: 350px;
        }
        div.message-wrapper {
          width: auto;
          display: table;
        }
        div.icon {
          display: table-cell;
        }
        div.arrow {
          background-image: icon-url("recurring-arrow.png");
          background-repeat: no-repeat;
          height: 24px;
          width: 27px;
        }
        display: inline-block !important;
        margin-left: 65px;
        color: #3366cc;
        font-family: "lavoshandyregular",Arial,Helvetica,"Nimbus Sans L",sans-serif;
      }
      #payment-options {
         .paypal-pay {
          font-style: italic;
          color: #003366;
        }
        .paypal-pal {
          font-style: italic;
          color: #336699;
        }
      }
    }
    #webform-component-donation--aclu-recurring {
      .psuedo-radio-label,
      input[type=radio] {
        &:hover + label .button-label {
          font-weight: bold;
        }
        &:checked + label {
          background-image: none;
          background-color: #ffcc33;
          font-weight: bold;
        }
        &:hover + label.option {
          background-color: #ffcc33;
        }
        + label {
          color: #373e44;
          font-size: 22px;
        }
        + label.option {
          display: inline-block;
          margin: 5px 20px 5px 0;
          padding: 0;
          height: 75px;
          line-height: 75px;
          min-width: 0;
          border: 1px solid #333;
          cursor: pointer;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          text-align: center;
          width: 270px;
        }
        + label .button-label {
          display: block;
          text-align: center;
        }
        border: 0;
        @include square-radiobutton(#fff, #ffcc33, #333, 24px 20px, 5px 20px 5px 0, 270px);
      }
      margin-bottom: 0;
    }
    .form-item label {
      vertical-align: middle;
    }
    .form-item label.option {
      float: left;
    }
    .form-item label {
      vertical-align: middle;
      &.option {
        float: left;
      }
    }
    .currency-in-input {
      .symbol {
        display: inline-block;
      }
      display: inline-block;
      border: 1px inset #444;
      margin: 10px 0 15px 0;
    }
  }
  // ecards IHO/IMO
  #webform-component-send-an-ecard-announcing-your-gift--yes-send-an-ecard {
    width: 100%;
  }
}
/* end paypal for desktop specified node */

/* for the readonlymode module */

/*** LEGAL INTAKE ***/

.node-type-legal-intake #webform-component-disclaimer {
  margin-top: 25px;
  h3 {
    font-weight: bold;
    font-family: $georgia;
    text-align: center;
  }
}

.node-type-webform {
  a.form-custom-button, input.form-custom-button, .form-custom-button {
    padding: 10px 15px 10px 15px;
  }
  label {
    font-size: 16px;
  }
}

/*** P2P Overrides ***/
.node-type-p2p-donation-form {
  span.fieldset-legend {
    color: $charcoal;
    font-size: toRem(22px);
    font-weight: 800;
    padding: 0;
    position: static;
  }
  span.fieldset-legend:after {
    content: '';
    display: none;
  }
  .pane-body p {
    font-size: toRem(18px);
    line-height: toRem(28px);
    margin: 0 0 1em 0; // replace all rhythm calls with 1em margin: 0 0 rhythm(1) 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .form-item input {
    color: #555555;
  }
  textarea, input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  .uneditable-input,
  .braintree-hosted-field {
    border: 1px solid #938b82;
    border-radius: 0px;
    height: auto;
  }
  .error input,
  .error select,
  .error textarea {
    border: 2px solid #cc0000;
  }
  .webform-client-form label {
    margin: 0 10px 0 0;
  }
  .control-group.success input,
  .form-item.success input {
    float: none;
    clear: initial;
  }
  .control-group.success .checkbox,
  .control-group.success .radio,
  .control-group.success input,
  .control-group.success select,
  .control-group.success textarea {
    color: #468847;
  }
  .control-group.success input, .control-group.success select, .control-group.success textarea {
    border-color: #468847;
  }
  .radio-label.radio-checked:before {
    background: none;
  }
  .checkbox-label:before,
  .radio-label:before {
    display: none;
    top: 0;
    padding-right: 0;

  }
  input#edit-submitted-donation-other-amount {
    text-indent: initial;
  }
  #webform-component-donation--other-amount .field-prefix {
    position: static;
    top: auto;
    left: auto;
    color: $default-form-text-color;
    margin: 0;
  }
  #webform-component-donation--other-amount.error .field-prefix {
    color: $form-error-color;
  }
  .webform-client-form label.error {
    color: $default-form-text-color;
  }
  .control-group input,
  .form-item input,
  .form-item select {
    color: #444444;
  }
  #main-content .alert.error {
    margin-top: 20px;
  }
  .alert ul li:before {
    content: '';
  }
  .alert ul li {
    list-style: disc;
  }
  div.donation-processing-wrapper {
    background: #ffffff;
    width: 200px;
  }
  .donation-processing-wrapper p {
    font-size: 14px;
    margin: 0;
  }
  .form-item.error label {
    color: #414141;
  }
}
.p2p-confirmation {
  .pane-content .webform-confirmation {
    padding: 0px;
    border: none;
    text-align: left;
    font-weight: normal;
  }
  .connect-list {
    padding: 0 0 0 30px;
    margin: 0 0 1.55556em 0;
  }
  legend {
    border-bottom: none;
  }
  .pane-pm-existing-pages {
    width: 880px;
    max-width: 100%;
  }
}

/*** TICKETED EVENT ***/

.node-type-ticketed-event {
  #edit-submitted-credit-card-information-expiration-date-card-expiration-month-wrapper,
  #edit-submitted-ticket-box table.ticket_box {
     .select  {
        min-height: 0;
        label {
           min-width:0;
        }
        &.auto-size {
          vertical-align: bottom;
          width: auto;
        }
     }
    .custom-select-container.auto-size {
      float: left;
      clear: both;
      vertical-align: bottom;
    }
    .extra-donation-text {
      font-family: DIN,Arial,Helvetica,sans-serif;
      font-weight: 700;
    }
  }
  @include susy-breakpoint($breakpoint-small, $small) {
    .ticket_box {
      overflow-x: auto;
      display: block;
    }
  }
  #fundraiser-tickets-extra-donation {
    width: 68%;
  }
  #extra-fields-container {
    @include susy-breakpoint($breakpoint-large, $large) {
      margin-right: 5%;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      margin-right: 7%;
    }
    @include susy-breakpoint($breakpoint-small, $small) {
      margin-right: 10%;
    }
  }
}
/**** EMAIL SIGNUP FORM ****/

.node-type-affiliate-email-signup-form {
  #welcome-back {
    display: none;
  }
  label {
    width: auto;
  }
  .webform-component-checkboxes .description  {
    padding-top: 0px;
  }
  .select {
    margin: 3px 0 1px 0;
    max-width: 100%;
    label {
      display: inline-block;
    }
  }
  select {
    max-width: 15rem;
    min-width: 15rem;
  }
  fieldset {
    border: 0 none;
    padding: 0;
    width: 100%;
    legend {
      width: 100%;
      border: 0 none;
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 15px;
    }
  }
}

/**** DONATION TABS ****/
#donation-tabs {
  h1.page-title {
    display: block;
    padding-top: 0;
  }
  .ui-tabs li a:focus {
    outline: 2px solid #8ddbff;
  }
  .node {
    padding-right: 12px;
  }
  .ui-tabs-panel {
     padding: 1em 0 1em 1em !important;
     form {
        padding-right: 15px;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
     }
  }
  .field-name-body.right-content {
    width: 100% !important;
    @include susy-breakpoint($breakpoint-medium, $medium) {
      width: 100% !important;
    }
    @include susy-breakpoint($breakpoint-small, $small) {
      width: 100% !important;
    }
  }
  #edit-submitted-donation-other-amount-wrapper,
  #webform-component-donation--amount,
  #webform-component-donation--recurring-amount,
  fieldset:not(:first-of-type),
  #donation-form-footer {
    display: none;
  }
}

.default-form-wrapper.quick-tab {
  #sidebar-right {
    margin-top: 15px;
  }
  .node-type-page h1.page-title:nth-of-type(1) {
    display: none;
  }
  .width-container {
    @include susy-breakpoint($breakpoint-medium, $medium) {
      width: 35.4444rem;
    }
  }
  #content {
    width: 100%;
    max-width: 845px !important;
  }
}


/**** MISC PAGES ****/

.misc-page {
  margin-top: auto;
  @include susy-breakpoint($breakpoint-small-medium, $small) {
    &.default-form-wrapper #wrapper {
      margin-top: toRem(50px);
    }
  }
}

/**** ERROR PAGES ****/

.node-error-page {
  &.default-form-wrapper #wrapper {
    margin-top: toRem(50px);
  }
  .share-label,
  .social-links {
    display: none;
  }
}

/**** LOGIN PAGE ****/

.page-aclu-login {
  &.default-form-wrapper #wrapper {
    margin-top: toRem(50px);
  }
}

/**** USER PAGE ****/

.section-user {
  &.default-form-wrapper #wrapper {
    margin-top: toRem(50px);
  }
}

/***  FUNDRAISER OFFLINE  ***/

.page-fundraiser-offline {
  #edit-submitted-cid-wrapper,
  #edit-submitted-ms-wrapper,
  .fieldset-offline-nid-wrapper {
    display: none;
  }
  form#fundraiser-offline-form {
    display: none;
  }
}

/**** PREFERENCE CENTER ***/

.page-preference-center {
  #innerwrapper {
    margin-left: auto;
    margin-right: auto;
    #main-content {
       display: block;
       padding-top: toRem(25px);
       a {
         text-decoration: underline;
       }
       .iframe-wrapper {
           display: block;
           height: 0;
           overflow: auto;
           @include susy-breakpoint($breakpoint-small, $small) {
             padding-bottom: 150rem;
             -webkit-overflow-scrolling: touch;
           }
           @include susy-breakpoint($breakpoint-medium, $medium) {
             padding-bottom: 150rem;
             -webkit-overflow-scrolling: touch;
           }
           margin-right: auto;
           margin-left: auto;
           position: relative;
           padding-bottom: 100%;
           padding-top: toRem(25px);
           border: solid 1px white;
           iframe {
             display: block;
             height: 100% !important;
             width: 100% !important;
             max-width: 100% !important;
             min-width: 100%;
             width: 1px;
             margin-right: auto;
             margin-left: auto;
             position: absolute;
             top: 0;
             left: 0;
           }
       }
    }
  }
}

/*** TICKETED EVENTS ***/
.node-type-ticketed-event .default-form-wrapper {
  @include susy-breakpoint($breakpoint-wide, $wide) {
    .field-name-body  {
      width: toRem(880px);
      max-width: toRem(880px);
    }
  }
  @include susy-breakpoint($breakpoint-small, $small) {
    .ticket_box {
      overflow-x: auto;
      display: block;
    }
  }

  .webform-component-textarea label {
    float: none;
  }

  h1.page-title {
    padding: 0px;
    margin-top: 60px;
    margin-bottom: 50px;
    color: $charcoal;
    font-size: toRem(48px);
    line-height: toRem(58px);
  }
  p {
    color: $charcoal;
  }
  table {
    margin: 0px;
  }
  .ticketed_event_info_callout_box tr, .ticket_box tr {
    border: none;
  }
  tr {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  }
  td {
    color: $charcoal;
    padding: 5px;
    vertical-align: top;
  }
  #webform-component-donor-information {
    margin-top: 3em;
  }
  .event-body-callout,
  .event-body-description {
    width: 580px;
    float: left;
  }
  .field-name-field-contact-information {
    float: right;
    width: 275px;
    position: relative;
    left: 30px;
    h4 {
      text-transform: uppercase;
      @extend %DIN-black;
      border-bottom: 2px solid #000;
      margin-bottom: .5em;
    }
    p {
      margin: 0px;
    }
    tbody {
      border: none;
    }
  }
  #edit-submitted-ticket-box {
    display: inline-block;
  }
  #edit-submitted-ticket-box .ticket_box {
    min-width: 415px;
    margin-top: 0px;
  }
  #webform-component-order-information-box {
    display: none;
    float: right;
    padding: 20px 15px;
    background-color: $affiliate-bg-secondary;
    width: 350px;
  }
  #webform-component-order-information-box.sticky {
    margin-top: 0 !important;
    position: fixed;
    top: 5px;
    display: inline-block;
  }
  .event-ticket-order-reserve {
    @extend %DIN-black;
    background-color: $orange;
    font-size: toRem(30px);
    text-align: center;
    padding: 10px 0px;
    width: 100%;
  }
  td.fundraiser-ticket-type-total {
    display: none;
  }
  .ticketed_event_info_callout_box {
    width: 100%;
    margin-bottom: 50px;
    tr {
      background-color: $blue-bright;
    }
    td {
      padding: 0px 15px 20px;
      @extend %DIN-regular;
    }
    tr:first-child td {
      padding-top: 20px;
    }
    td:first-child {
      text-transform: uppercase;
      @extend %DIN-black;
      width: 115px;
    }
    td p {
      margin: 0 !important;
    }
    .map_info {
      position: relative;
      top: 5px;
    }
  }
  td.ticket_type {
    @extend %DIN-black;
  }
  td.ticket_description {
    @extend %DIN-regular;
  }
  .stuck {
    position: fixed;
    top: 30px;
    :not{
      position: absolute;
      top: 0;
    }
  }
  .stuck-bottom {
    position: absolute;
    bottom: 0;
  }
  #webform-component-tickets .fieldset-wrapper {
    width: 100%;
    max-width: 880px;
  }
  #webform-component-tickets--order-information-box {
    display: inline-block;
    width: 390px;
  }

  table.ticket_box {
    tbody {
      border-top: none;
    }
    th {
      display: none;
    }
    td.ticket_description {
      display: none;
    }
    td {
      padding-bottom: 25px;
    }
    td.ticket_type {
      padding-right: 20px;
    }
    td.ticket_type #ticket_type_custom_desc {
      font-weight: 200;
    }
    tr:last-child td {
      padding-bottom: 0px;
    }
    td:first-child {
      max-width: 90px;
    }
    td:last-child {
      padding-left: 15px;
      padding-top: 9px;
    }
    .custom-select-container,
    .form-item select {
      width: 4rem;
      height: 39px;
    }
  }
  .event-ticket-order-quantity {
    margin-top: 12px
  }
  .total_ticket_info_table {
    border-top: 1px solid #AD9A72;
    margin: 2em 0;
    @extend %DIN-black;
  }
  #webform-component-ticket-options-total-cost,
  .event-ticket-select-total {
    text-align: right;
    padding-right: 20px;
    padding-left: 10px;
  }
  .event-ticket-order-quantity {
    font-style: italic;
    font-size: toRem(14px);
    line-height: toRem(18px);
    font-family: $georgia;
  }
  .event-ticket-select-total-quantity {
    @extend %DIN-black;
    padding-right: 3px;
  }
  .individual_ticket_info_table {
    @extend %DIN-regular;
    line-height: toRem(23px);
    tbody {
      border: none;
    }
    td {
      padding-top: 10px;
    }
    td.ticket_quant {
      font-weight: 800;
    }
    td[id^=cust-product] {
      font-weight: 700;
    }
    td[id^=cust-ticket-cost] {
      text-align: right;
      padding-right: toRem(20px);
    }
    tr {
      border: none;
    }
    tr:first-of-type td {
      padding-top: 0px;
    }
  }
  .total_ticket_info_table {
    tr {
      border-left: none;
      border-right: none;
      border-top: solid 1px #373E44;
    }
  }
  #fundraiser-tickets-extra-donation-display {
    display: none;
  }
  .form-item-submitted-ticket-box-fundraiser-tickets-extra-donation {
    width: auto;
    padding-right: 20px;
  }
  .currency-symbol {
    padding-right: 0;
    margin-right: 0;
  }
  #webform-component-donor-information--mobile-opt-in-disclaimer,
  .form-item-submitted-donor-information-email-opt-in-1 label {
    font-family: DIN,Arial,Helvetica,sans-serif;
    font-size: 14px;
  }
  .form-item select {
    border-radius: 0;
  }
  @include susy-breakpoint($breakpoint-large, $large) {
    .event-body-callout {
      width: 475px;
    }
    .field-name-field-contact-information {
      width: 273px;
    }
    .ticket_box {
      min-width: 415px;
    }
    #webform-component-order-information-box {
      width: 280px;
      margin-left: 0;
    }
    #webform-component-tickets--order-information-box {
      max-width: 750px;
    }
  }
  @include susy-breakpoint($breakpoint-medium, $medium) {

    h1.page-title {
      margin-top: 43px;
      margin-bottom: 35px;
      font-size: toRem(30px);
      line-height: toRem(40px);
    }
    .event-body-callout {
      width: 355px;
    }
    .event-info {
      position: relative;
    }
    .field-name-field-contact-information {
      width: 264px;
    }
    #edit-submitted-ticket-box table.ticket_box {
      min-width: 355px;
      td:last-child {
        min-width: 267px;
      }
    }
    #webform-component-order-information-box {
      width: 205px;
      margin-left: 0;
    }
    #webform-component-order-information-box button.event-ticket-order-reserve .tix {
      display: none;
    }
    #webform-component-ticket-options-total-cost,
    .event-ticket-select-total {
      padding-right: 0px;
    }
    .total_ticket_info_table {
      margin: 1.5em 0;
      td {
        padding-top: 20px;
      }
    }
    #webform-component-tickets--order-information-box {
      max-width: 620px;
    }
  }

  @include susy-breakpoint($breakpoint-small, $small) {
    h1.page-title {
      font-size: toRem(26px);
      line-height: toRem(30px);
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .event-body-callout {
      width: 100%;
      float: none;
    }
    .field-name-field-contact-information {
      width: 100%;
      left: inherit;
      float: none;
      margin-top: 1em;
      margin-bottom: 2em;
    }
    .ticketed_event_info_callout_box {
      @include full-bleed-breakout;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: $blue-bright;
      display: block;
    }
    #container {
      overflow: hidden;
    }
    .ticketed_event_info_callout_box td {
      display: block;
      padding-left: 10px;
    }
    .ticketed_event_info_callout_box td:first-child {
      padding-bottom: 0px;
    }
    .ticketed_event_info_callout_box tr:first-child td {
      padding-top: 0px;
    }
    .ticketed_event_info_callout_box tr:first-child td:first-child {
      padding-top: 20px;
    }
    .ticket_box {
      min-width: 100%;
    }
    .event-ticket-title {
      display: block;
    }
    #webform-component-order-information-box {
      width: 100%;
      box-sizing: border-box;
      margin-top: 1em;
      margin-bottom: toRem(40px);
    }
    .event-ticket-order-reserve {
      font-size: toRem(24px);
    }
    #webform-component-order-information-box button.event-ticket-order-reserve .tix {
      display: none;
    }
  }
  #ticket-box-ajax-wrapper {
    display: inline-block;
    @include susy-breakpoint($breakpoint-wide, $wide) {
      width: toRem(500px);
    }
    @include susy-breakpoint($breakpoint-large, $large) {
      width: toRem(440px);
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      width: toRem(384px);
    }
    @include susy-breakpoint($breakpoint-small, $small) {
      display: block;
    }
  }
  #custom-ticketarea-wrapper {
    @include susy-breakpoint($breakpoint-small, $small) {
      width: 100%;
    }
  }
}

/* Recurring Order Page */
.default-form-wrapper #orders tr.recurring-order {
  &.active {
    background-color: $beige;
  }
  td {
    padding-right: 2px;
  }
  td a:hover {
    background-color: $gold;
  }
}

/* Canvass Form */
.canvass {
  .confirmation {
    .page-title {
      display: none;
    }
  }
  &.monthly {
    .default-form-wrapper {
      fieldset legend {
        background-color: #fab022;
        color: #fff;
      }
      .webform-component-checkboxes {
        input[type="checkbox"] + label::before {
          background-image: icon-url('checkboxes-orange-bg.png');
          @include susy-breakpoint($breakpoint-small, $small) {
            background-image: icon-url("checkboxes-large-orange-bg.png") no-repeat;
          }
        }
      }
     }
  }
  // Canvasser forms stay unpublished when in use - override the pink background color
  .node-unpublished {
    background-color: #fff;
  }
}

/* Generic Form */
.node-type-webform .default-form-wrapper,
.node-type-legal-intake .default-form-wrapper,
.node-type-volunteer-form .default-form-wrapper,
.node-type-signup-form .default-form-wrapper {
  .field-name-body  {
    @include susy-breakpoint($breakpoint-wide, $wide) {
      max-width: 100%;
      width: toRem(880px);
    }
  }
  .webform-component-checkboxes > label + div {
    display: inline-block;
    margin: 15px 0 0 10px;
    @include susy-breakpoint($breakpoint-small, $small) {
      margin: 0;
    }
  }
  hr {
    border: 0 none;
    height: 1px;
    color: #ccc; /*cross browser*/
    background-color: #ccc;
    margin: 10px 0 0 0;
  }
  .node-webform {
    max-width: 90%;
  }
  form > .form-item {
    width: 100%;
  }
  .form-item textarea,
  .form-item input[type="text"],
  .form-item input.form-text,
  .form-item .webform-datepicker > .form-item,
  .webform-component-select .select .custom-select-container {
     margin: 30px 0 0 30px;
     @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: auto;
         margin-right: auto;
     }
   }
   .form-item .description,
   .form-item .grippie {
     margin-top: 0;
     margin-left: 30px;
     @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: 0;
     }
   }
   .form-item .webform-grid {
     margin-left: 30px;
     min-width: 0;
     width: 95%;
     margin-top: 30px;
     margin-bottom: 0;
     th.webform-grid-option {
       padding-right: 0;
     }
     @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: 0;
     }
   }
  .form-item {
    border: none;
    font-size: toRem(19px);
    padding: 5px 0px 0px 0px;
    border: none;
    font-weight: normal;
    label {
      font-size: toRem(19px);
      padding: 15px 15px 0 5px;
      font-weight: bold;
     }
     .container-inline div {
       display: inline-block;
     }
     input.webform-calendar {
       vertical-align: baseline;
     }
     input[type="text"] {
       -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
       -moz-box-sizing: border-box;    /* Firefox, other Gecko */
       box-sizing: border-box;
     }
     textarea, .grippie {
       -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
       -moz-box-sizing: border-box;    /* Firefox, other Gecko */
       box-sizing: border-box;         /* Opera/IE 8+ */
       float: none;
       display: inline-block;
       width: 93%; /*offset margin left*/
       @include susy-breakpoint($breakpoint-small, $small) {
         width: 100%;
       }
     }



     &.webform-component-checkboxes {
      label.option {
       font-weight: normal;
       font-size: toRem(19px);
       padding: 5px 0px 0px 20px;
       border: none;
       box-sizing: border-box;
      }
      @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: auto;
         label.option {
           padding-left: 0;
         }
      }
    }

    &.webform-component-radios,
    &.webform-component-webform_time {
      .form-type-radio {
        padding: 5px 0px 0px 20px;
        @include susy-breakpoint($breakpoint-small, $small) {
          padding-left: 0px;
        }
        border: none;
        label {
          font-weight: normal;
          font-size: toRem(19px);
          line-height: 19px;
          padding: 0;
          margin-right: 0;
          bottom: 2px;
          position: relative;
        }
      }
      @include susy-breakpoint($breakpoint-small, $small) {
        margin-left: auto;
        label.option {
          padding-left: 0;
        }
      }
    }

    .form-checkboxes,
    .webform-component-checkboxes {
      .form-item {
        float: none;
      }
      input[type="checkbox"] {
        /* Match the image dimensions */
        @include susy-breakpoint($breakpoint-small, $small) {
          width: 23px;
          height: 23px;
        }
        + label:before {
          display: inline-block;
          @include susy-breakpoint($breakpoint-small, $small) {
            width: 23px;
            height: 23px;
            margin-right: 0px;
            background: icon-url("checkboxes-blue-bg.png") no-repeat;
            content: " ";
            vertical-align: bottom;
            background-position: 0 -23px;
            float: none;
          }
        }
        &:focus + label:before {
          @include susy-breakpoint($breakpoint-small, $small) {
            background-position: 0 -23px;
            float: none;
          }
        }
        &:checked {
          + label:before, &:focus + label:before {
            background-position: 0 0px;
          }
          @include susy-breakpoint($breakpoint-small, $small) {
            background-position: 0 0px;
            float: none;
          }
        }
      }
    }
  }

  #webform-component-disclaimer p {
    font-family: $georgia;
    font-size: toRem(18px);
  }
}
