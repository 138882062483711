/* stylelint-disable no-duplicate-selectors  */
.node-type-donation-form .default-form-wrapper.action-donation-form.affiliate-template {
  h1,
  div.page-title h1,
  div.subtitle h2,
  p.page-title,
  h1.page-title {
    display: block;
  }

  .support-work {
    font-family: $DIN;
    font-weight: 700;
  }

  a.social-share-link {
    font-family: $DIN;
    font-weight: 700;
    position: relative;
  }

  @include susy-breakpoint($breakpoint-medium, $medium) {
    h1,
    div.page-title h1,
    p.page-title,
    div.subtitle h2,
    h1.page-title {
      display: block;
      font-size: toRem(30px);
      line-height: toRem(35px);
    }
  }

  @include susy-breakpoint($breakpoint-small, $small) {
    .logo-nav {
      margin-left: 0;
    }
    h1,
    h1.page-title {
      display: block;
      font-size: toRem(30px);
      line-height: toRem(30px);
    }

    .aclu-share-social::after {
      content: "Support our work:";
      text-transform: uppercase;
      font-size: toRem(24px);
      font-family: $DIN;
      font-weight: 700;
    }

    .support-work-wrapper {
      display: none;
    }

    a.social-share-link span,
    a.social-share-link {
      color: #353E45;
    }

    .support-work-wrapper {
      @include skew(0deg, 0deg);
      border: none;
      width: 100%;
      margin-right: 0px;
    }

    .support-work,
    #share-facebook-simple,
    #share-twitter-simple,
    #share-email-simple {
      @include skew(0deg, 0deg);
    }

    .support-work {
      padding-left: 0px;
    }

    .share-facebook-simple-wrapper,
    .share-twitter-simple-wrapper,
    .share-email-simple-wrapper {
      background-color: transparent;
      @include skew(0deg, 0deg);
      width: 100%;
      border: 1px solid #C9C9C9;
      padding-top: 12px;
      padding-bottom: 12px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }

    .share-email-simple-wrapper {
      margin-bottom: 30px;
    }

    .aclu-donate-share-simple {
      margin-left: 0px;
      overflow: hidden;
      height: auto;
    }

    .aclu-share-social {
      width: 100%;
      margin-left: 0;
      padding-top: 0;
      overflow: hidden;
    }

    a.social-share-link {
      margin: 0px;
      background: none;
      text-align: center;
      height: auto;
    }

    a.social-share-link{
      display: inline-block;
      padding-left: 50px;
      padding-top: 5px;
      height: 35px;
      background-size: 40px;
      position: relative;
      top: 0px;
    }
  }
}
