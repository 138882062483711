
@include susy-breakpoint($breakpoint-small, $small) {
  .affiliate-template .header-webform .logo-nav {
    margin-top: 0;
  }
}

.field-field-petition-message,
#right form,
.node-type-springboard-petition .default-form-wrapper .aclu-purpose-share .aclu-share-tracker,
.form-layout-container fieldset legend {
  background-color: $affiliate-bg-primary;
}

.node-type-ticketed-event .form-layout-container #donation-form-column-wrapper fieldset legend,
.node-type-donation-form .form-layout-container #donation-form-column-wrapper fieldset legend {
  background-color: $blue-light;
}

#webform-component-sba-quicksign,
#right #webform-component-sba-quicksign,
#right #webform-component-sba-quicksign form {
  background-color: $affiliate-bg-primary;
}

.node-type-springboard-petition .default-form-wrapper .aclu-purpose-share #purpose-share-fb,
.node-type-springboard-petition .default-form-wrapper .aclu-purpose-share #purpose-share-twitter,
.node-type-springboard-petition .default-form-wrapper .aclu-purpose-share #purpose-share-email {
  border-color: $affiliate-bg-primary;
}

.node-type-springboard-petition .default-form-wrapper input#edit-submit,
.node-type-springboard-petition .default-form-wrapper input#edit-submit.btn:hover,
.node-type-springboard-petition .default-form-wrapper a#fb-share,
.node-type-springboard-petition .default-form-wrapper a#twitter-share,
.node-type-springboard-petition .default-form-wrapper a#aclu-share-show-mail {
  background-image: none;
  background-color: $affiliate-button-primary;
}

#webform-component-sba-quicksign input[type="submit"] {
  background-image: none;
  background-color: $affiliate-button-secondary;
}

.webform-client-form #webform-component-biographical-information {
  padding: 0;
  border: none;
  margin-top: 0px;
  legend {
    display: none;
  }
}

#webform-component-biographical-information--first-name {
  float: left;
}

#webform-component-biographical-information--last-name {
  float: right;
}

#webform-component-biographical-information--first-name,
#webform-component-biographical-information--last-name {
  width: 49%;
  clear: none;
}

.field-field-petition-message {
  padding-bottom: 0px;
}

@include susy-breakpoint($breakpoint-medium, $medium) {
  .aclu-purpose-share {
    border: none;
  }
}
