.affiliate-template fieldset legend {
  background-color: $affiliate-bg-primary;
  width: 100%;
}

/*  Thermometer styling  */
.wg-goal-autodisappearing,
.wg-goal {
  background-color: $affiliate-bg-tertiary;
  color: white;
  p {
    color: white;
  }
  .wg-progress .bar {
    background: #f01a1c;
    background: linear-gradient(to right, #f86d19, #f01a1c) !important;
  }
  .bar:after {
    background-color: #F72D1D !important;
  }
  .wrapper-type-message-action & {
      margin-top: 18px;
  }
}
