.affiliate-template.wrapper-type-message-action {
  #webform-component-salutation-disclaimer {
    font-weight: normal;
    font-size: 14px;
  }
  #user-information-wrapper {
    background-color: $affiliate-bg-primary;
  }
  #sba-messages-form {
    background-color: $affiliate-bg-secondary;
    label {
      padding-left: 0;
    }
  }
  .action-message-left fieldset.sba-message,
  .sba-message {
    padding: 0.95556em 0.95556em 0;
    margin-top: 0;
    p {
      margin-bottom: 10px;
    }
    .form-item {
      margin: 10px 0;
    }
  }
  div.sba-message {
    padding-top: 0;
    padding-bottom: 0.95556em;
  }
  fieldset legend {
    background: none;
  }
  input#edit-submit,
  input#edit-submit:hover,
  #edit-actions input[type="submit"]{
    border: none;
    background-color: #E33033;
    background-image: none;
  }
  .form-item.webform-component.webform-component-checkboxes.control-group { 
    padding: 0;
    label {
      padding: 0;
    }
  }
  .form-item label {
    padding-top: 10px;
  }
}

@include susy-breakpoint($breakpoint-medium-up, $large) {
  .affiliate-template.wrapper-type-message-action {
    #webform-component-sba-quicksign {
      margin-top: 0;
    }
  }
}

@include susy-breakpoint($breakpoint-small, $small) {
  #webform-component-sbp-first-name.control-group, #webform-component-sbp-last-name.control-group {
    float: none;
    width: 100% !important;
    padding: 0;
  }
}
