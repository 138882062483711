.node-type-donation-form.affiliate-template,
.affiliate-template {
  .header-webform,
  .header-webform .nav-container {
    height: toRem(120px);
    @include susy-breakpoint($breakpoint-small-medium, $breakpoint-small-medium ) {
      height: toRem(50px);
    }

  }
  .header-webform {
    background: none;
    background-color: #fff;
    .logo-nav,
    .logo-nav li {
      float: none;
    }
    .logo-nav {
      width: auto;
      max-width: none;
    }
  }
  h1,
  div.page-title h1,
  h1.page-title {
    color: $charcoal;
    font-size: toRem(34px);
    line-height: toRem(41px);
  }
  .subscription_message {
    margin-top: toRem(36px);
  }
  .node input#edit-submit,
  input#edit-submit,
  #edit-submit.form-submit {
    background-color: #FF8A00;
    border: 2px solid #FF8A00;
  }
  .node input#edit-submit:hover,
  #edit-submit.form-submit:hover {
    background-color: #094b76;
    border: 2px solid #094b76;
  }
}

.affiliate-template .logo.affiliate-logo {
  background-size: contain;
  background-repeat: no-repeat;
  width: 180px;
  height: 99px;
  margin-left: 0px;
  @include susy-breakpoint($breakpoint-large-up, $large) {
    text-indent: 0px;
  }
}

.affiliate-template .header-webform .logo.affiliate-logo {
  display: inline-block;
  float: none;
}

#footer-webform .affiliate-logo {
  border: 4.5px solid white;
  background-color: white;
}

.affiliate-mobile-header {
  display: none;
}

.affiliate-mailing-address,
.affiliate-website {
  font-size: $base-font-size;
  line-height: $base-line-height;
}

.affiliate-mailing-address {
  .country {
     display: none;
   }
  .addressee {
    text-transform: uppercase;
    display: block;
  }

  @include susy-breakpoint($breakpoint-medium-up, $medium) {
    div {
      display: inline;
    }
    .street-block::after {
      content: ",\0020";
    }
  }
}

#block-menu-menu-secondary-footer-menu {
  margin-top: 3em;
}

.default-form-wrapper #footer-webform .top-wrapper {
  margin-bottom: 3em;
}

#footer-webform #block-menu-menu-social-links ul.menu li:last-of-type {
  margin-right: 0px;
}

#footer-webform img {
  width: 190px;
  margin-bottom: toRem(16px);
  border: 4.5px solid white;
}

.ca_affiliate ul#top-block {
  margin-bottom: 3em;
}

@include susy-breakpoint($breakpoint-medium-up, $large) {
  .affiliate-template .header-webform {
    border-bottom: toRem(20px) solid $aclu-red-amaranth;
  }
}

@include susy-breakpoint($breakpoint-small-medium, $small) {
  .affiliate-template .header-webform,
  .wrapper-type-petition .header-webform {
    border: none;
    height: toRem(50px);
    background-color: $aclu-red-amaranth;
    .logo.affiliate-logo {
      display: none;
    }
    .menu-wrapper {
      height: toRem(65px);
      margin-top: 0;
    }
  }
  .affiliate-mobile-header {
    display: inline-block;
    vertical-align: top;
    color: #423a38;
    font-size: toRem(21px);
    font-family: $GTAMCOND;
    font-weight: 400;
    line-height: toRem(28px);
    margin-top: -9px;
    margin-bottom: 0px;
    &:hover {
      color: #005aaa;
    }
  }
}

@include susy-breakpoint($breakpoint-small, $small) {
  .affiliate-template .header-webform {
    height: toRem(50px);
    margin-bottom: 0px;
    .logo.affiliate-logo {
      display: none;
    }
    .menu-wrapper {
      height: toRem(50px);
      margin-top: 0;
    }

  }
  .affiliate-mobile-header {
    font-size: toRem(18px);
    line-height: toRem(21px);
    font-family: $GTAMCOND;
    margin-top: 4px;
  }
}

@include susy-breakpoint($breakpoint-small-medium, $small) {
  #footer-webform .ca_affiliate #block-menu-menu-social-links ul.menu {
    margin: toRem(20px) 0 toRem(15px);
  }
}

@include susy-breakpoint($breakpoint-small, $breakpoint-small) {
  .affiliate-address-line-1 {
    display: block;
  }
  .affiliate-city-comma {
    display: none;
  }
}
