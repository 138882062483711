.wrapper-type-social-action.affiliate-template {
  #take-action-wrapper,
  .processing-text {
    background-color: $affiliate-bg-primary;
  }

  input#edit-submit,
  input#edit-submit.btn:hover {
    background-image: none;
    background-color: $affiliate-button-primary;
    border: none;
  }

  .uneditable-message-preview {
    background-color: $affiliate-bg-secondary;
  }
}