.wrapper-type-tribute.affiliate-template.default-form-wrapper {
  overflow: hidden;

  .node-webform {
    max-width: 100%;
  }

  .field-name-body {
    width: 100%;
    @include susy-breakpoint($breakpoint-wide, $wide) {      
      max-width: toRem(750px);
    } 
  }

  .webform-client-form {
    width: 93%;
    background-color: #8ddbff;
    padding: toRem(30px);    
    float: left;
    margin-top: toRem(40px);
    @include susy-breakpoint($breakpoint-small, $small) {
      &:not(.tribute-thank-you) {
        @include full-bleed-breakout;
      }
      margin-top: toRem(20px);
      width: 100%;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      margin-top: toRem(20px);
      width: 90%;
    }
    @include susy-breakpoint($breakpoint-large, $large) {
      margin-top: toRem(20px);
      width: 92%;
    }

    .webform-component-fieldset {
      @include susy-breakpoint($breakpoint-large, $large) {
        width: 75%;      
      }
      @include susy-breakpoint($breakpoint-wide, $wide) {      
        width: 75%;
      }      
    }

    #webform-component-tribute-image {
      display: inline-block;
      width: 40%;
      margin: 0;
      padding: 0;
      @include susy-breakpoint($breakpoint-small, $small) {
        width: 100%;
      }
    }

    #webform-component-tribute-text {
      margin: 0;
      display: inline-block;
      width: 50%;
      vertical-align: top;
      padding: 0 toRem(40px) toRem(40px) toRem(20px);
      font-family: $DIN;
      font-size: toRem(21px);
      @include susy-breakpoint($breakpoint-small, $small) {
        width: 94%;
        padding: toRem(40px) 0;
      }
      @include susy-breakpoint($breakpoint-medium, $medium) {
        width: 46%;
      }
    }

    .form-item textarea,
    input[type="text"],
    input[type="email"] {
      margin: 0;
      max-width: inherit;
    }

    .form-item label {
      display: none;
    }

    #webform-component-biographical-information--sbp-first-name,
    #webform-component-biographical-information--mail {
      width: 49%;
      float: left;
      clear: none;
      @include susy-breakpoint($breakpoint-small, $small) {
        width: 100%;
      }
    }

    #webform-component-biographical-information--sbp-zip,
    #webform-component-biographical-information--sbp-last-name {
      width: 49%;
      float: right;
      clear: none;
      @include susy-breakpoint($breakpoint-small, $small) {
        width: 100%;
      }
    }

    #webform-component-biographical-information--tribute-personal-note {
      width: 100%;
      textarea {
        width: 100%;
      }
      *::-webkit-input-placeholder {
        color: #757575;
      }
      *:-moz-placeholder {
        color: #757575;
      }
      *::-moz-placeholder {
        color: #757575;
      }
      *:-ms-input-placeholder {
        color: #757575;
      }
      *::-ms-input-placeholder {
        color: #757575;
      }
    }
  }

  .tribute-column-wrapper.tribute-thank-you {
    background-color: #fff;
    padding: 0;
    margin-top: 0;
    @include susy-breakpoint($breakpoint-small, $small) {
      width: 100%;    
    }
  }

  #webform-component-biographical-information--email-opt-in {
    margin-top: toRem(18px);
    padding: 0;
    #edit-submitted-biographical-information-email-opt-in,
    .form-item,
    label {
      margin: 0;
      padding: 0;
    }
    #edit-submitted-biographical-information-email-opt-in label {
      display: inline-block;
    }
  }

  @include susy-breakpoint($breakpoint-small, $small) {
    #wrapper {
      padding-bottom: 0;
    }
    #footer-webform {
      margin-top: 0;
    }
  }
}
